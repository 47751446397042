import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import _ from 'lodash'
import axiosInstance from '../../api'
import { getBoard } from './boardSlice'
import { toast } from '../../utils/toast'
import uploadFileToS3 from '../../utils/s3FileUpload'

export const getCards = createAsyncThunk('scrumboardApp/cards/getCards', async (boardId) => {
  const response = await axios.get(`/api/scrumboard/boards/${boardId}/cards`)
  const data = await response.data

  return data
})

type AttachedFile = {
  fileName: string;
  fileSize: number;
  mimetype: string;
  url: string;
};

export const uploadFiles = createAsyncThunk(
  'uploadFiles/files',
  async (files: File[], { dispatch, getState }: any) => {


    let attachedFiles: AttachedFile[] = [];

    if (files.length > 0) {
      attachedFiles = await Promise.all(
        Array.from(files).map(async (file) => {
          const { fileName, fileSize, mimetype, url, originalName } = await uploadFileToS3(
            file,
            false,
            false
          );
          return {
            originalName,
            fileName,
            fileSize,
            mimetype,
            url,
          };
        })
      );
    }
    return attachedFiles
  }
)

export const deleteCardFile: any = createAsyncThunk(
  'scrumboardApp/cards/deleteFile',
  async ({ cardId, file }: any, { dispatch, getState }: any) => {
    try {
      const { data } = await axiosInstance.delete(`/card/delete_card_file`, {
        params: { cardId, file },
      })
      return data.data.cardInfo
    } catch (error) {
      console.log('error', error)
      return {}
    }
  }
)

export const inQueueUploadFile: any = createAsyncThunk(
  'scrumboardApp/cards/inQueueUploadFile',
  async ({ cardId, uploadedFileInfo }: any) => {
    try {
      const { data } = await axiosInstance.post(`/card/card_upload_file`, {
        cardId,
        uploadedFileInfo,
      })
      toast({ toastType: 'SUCCESS', message: `${uploadedFileInfo.originalName} is uploaded`, duration: false })
      return data.data
    } catch (error) {
      console.log('error', error)
      return {}
    }
  }
)

export const saveOrAddNewQueryResult: any = createAsyncThunk(
  'scrumboardApp/cards/updateSummarization',
  async (
    { boardId, fileContent, cardId, fileName }: { boardId: string; fileContent: string, cardId, fileName: string }, { dispatch, getState }: any,
  ) => {
    try {

      const file = new Blob([fileContent], { type: 'text/plain' });
      const convertedFile = new File([file], fileName, { type: 'text/plain' });
      const uploadedFileResponse = await uploadFileToS3(convertedFile, false, true);
      const { data } = await axiosInstance.post(`/card/save_query_result`, {
        boardId,
        fileContent,
        cardId,
        summarizedFile:
        {
          updatedDate: new Date(),
          ...uploadedFileResponse
        }
      })

      toast({ toastType: 'SUCCESS', message: 'Your new file is saved', duration: false })
      dispatch(getBoard(boardId))
      return { lists: data.data.cardInfo };
    } catch (error) {
      toast({ toastType: 'ERROR', message: 'Error while saving file', duration: false })
      console.log('error', error)
      return {}
    }
  }
)
const cardsAdapter = createEntityAdapter({})

export const { selectAll: selectCards, selectById: selectCardById } = cardsAdapter.getSelectors(
  (state: any) => state.scrumboardApp.cards
)

const cardsSlice = createSlice({
  name: 'scrumboardApp/cards',
  initialState: {
    summarizedDocModal: false,
    editorModal: false,
    fileInfo: {},
    queryGeneratorModal: false,
    cardId: ''
  },
  reducers: {
    handleSummarizedDocModal: (
      state: any,
      { payload }: { payload: { show?: boolean; fileInfo?: { cardId: string; fileName: string } } }
    ) => {
      const { show, fileInfo } = payload
      if (show) {
        state.fileInfo = fileInfo
      } else {
        state.fileInfo = {}
      }
      state.summarizedDocModal = show
    },
    handleEditorModal: (
      state: any,
      { payload }: { payload: { show?: boolean; fileInfo?: { _id: string, cardId: string; fileName: string, url: string }, isEdit: boolean } }
    ) => {
      const { show, fileInfo, isEdit } = payload
      if (show) {
        state.fileInfo = fileInfo
      } else {
        state.fileInfo = {}
      }
      state.isEdit = isEdit
      state.editorModal = show
    },
    handleGenerateQueryModal: (
      state: any,
      {
        payload,
      }: { payload: { show?: boolean; cardId?: string } }
    ) => {
      const { show, cardId } = payload

      if (show) {
        state.cardId = cardId
      } else {
        state.cardId = cardId
      }

      state.queryGeneratorModal = show
    },
  },

})

export const { handleSummarizedDocModal, handleEditorModal, handleGenerateQueryModal } =
  cardsSlice.actions

export default cardsSlice.reducer
