import { useLayout } from '../layout/core'
import { ThemeModeComponent } from '../assets/ts/layout'

export const toAbsoluteUrl = (pathname: string) => process.env.PUBLIC_URL + pathname

export const useIllustrationsPath = (illustrationName: string): string => {
  const { config } = useLayout()

  const extension = illustrationName.substring(
    illustrationName.lastIndexOf('.'),
    illustrationName.length
  )
  const illustration =
    ThemeModeComponent.getMode() === 'dark'
      ? `${illustrationName.substring(0, illustrationName.lastIndexOf('.'))}-dark`
      : illustrationName.substring(0, illustrationName.lastIndexOf('.'))
  return toAbsoluteUrl(
    `/media/illustrations/${config.illustrations?.set}/${illustration}${extension}`
  )
}


export const getFileSize = (bytes: number) => {
  if (bytes === 0) return '0 Bytes'
  const k = 1024
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
  const i = Math.floor(Math.log(bytes) / Math.log(k))
  return (Math.round((bytes / Math.pow(k, i)) * 100) / 100).toFixed(1) + ' ' + sizes[i]
}
export const getFileType = (fileType: string) => {
  switch (fileType) {
    case 'application/pdf':
      return { fileType: 'PDF File', icon: '/media/svg/files/pdf.svg', extension: '.pdf' }
    case 'text/plain':
      return { fileType: 'DOC File', icon: '/media/svg/files/doc.svg', extension: '.txt' }
    default:
      return { fileType: 'PDF File', icon: '/media/svg/files/pdf.svg', extension: '.txt' }
  }
}
