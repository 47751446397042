import { lazy, FC, Suspense } from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'
import { MasterLayout } from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import { getCSSVariableValue } from '../../_metronic/assets/ts/_utils'
import { WithChildren } from '../../_metronic/helpers'

const PrivateRoutes = () => {
  const Dashboard = lazy(() => import('../pages/dashboard'))
  const ProposalDetails = lazy(() => import('../pages/proposalDetails'))
  const ProposalOverview = lazy(() => import('../pages/proposalOverview'))
  const Timeline = lazy(() => import('../pages/timeline'))
  const DocumentsLibrary = lazy(() => import('../pages/documents-library'))
  const PlansPage = lazy(() => import('../pages/plans'))
  const ProposalListPage = lazy(() => import('../pages/proposal-list'))
  const SettingsPage = lazy(() => import('../pages/settings'))

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        <Route
          path='proposals/:id'
          element={
            <SuspensedView>
              <ProposalOverview />
            </SuspensedView>
          }
        />

        <Route
          path='proposals/:id/details'
          element={
            <SuspensedView>
              <ProposalDetails />
            </SuspensedView>
          }
        />
        <Route
          path='settings'
          element={
            <SuspensedView>
              <SettingsPage />
            </SuspensedView>
          }
        />
        <Route
          path='timeline'
          element={
            <SuspensedView>
              <Timeline />
            </SuspensedView>
          }
        />

        <Route
          path='dashboard'
          element={
            <SuspensedView>
              <Dashboard />
            </SuspensedView>
          }
        />

        <Route
          path='proposals'
          element={
            <SuspensedView>
              <ProposalListPage />
            </SuspensedView>
          }
        />

        <Route
          path='plans'
          element={
            <SuspensedView>
              <PlansPage />
            </SuspensedView>
          }
        />

        <Route
          path='documents-library'
          element={
            <SuspensedView>
              <DocumentsLibrary />
            </SuspensedView>
          }
        />

        <Route path='auth/*' element={<Navigate to='/proposals' />} />
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export { PrivateRoutes }
