import storage from 'redux-persist/lib/storage'
import { configureStore } from '@reduxjs/toolkit'
import scrumboardAppReducers from './scrumBoard'

const PersistConfig = {
  key: 'root',
  storage,
}

export const store = configureStore({
  reducer: {
    scrmapp: scrumboardAppReducers,

  },

})

// export const persistor = persistStore(store)

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
