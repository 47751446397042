/* eslint-disable react/jsx-no-target-blank */
import { SidebarMenuItem } from './SidebarMenuItem'

const SidebarMenuMain = () => {
  return (
    <>
      {/* <SidebarMenuItem to='/dashboard' icon='dashboard' title='Dashboard' /> */}
      <SidebarMenuItem to='/proposals' icon='course-overview' title='Proposals' />
      {/* <SidebarMenuItem to='/timeline' icon='course-details' title='Proposal Timeline' /> */}
      <SidebarMenuItem to='/documents-library' icon='library' title='Document Library' />
    </>
  )
}

export { SidebarMenuMain }
