import clsx from 'clsx'
import { toAbsoluteUrl } from '../../../helpers'
import { HeaderUserMenu } from '../../../partials'
import { useAuth } from '../../../../app/modules/auth'


const itemClass = 'ms-1 ms-md-4'
const userAvatarClass = 'symbol-35px'

const Navbar = () => {
  const { currentUser } = useAuth()
  return (
    <div className='app-navbar flex-shrink-0'>
      <div className={clsx('app-navbar-item', itemClass)}>
        <div
          className={clsx('cursor-pointer symbol', userAvatarClass)}
          data-kt-menu-trigger="{default: 'click'}"
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
        >
          <img alt='Logo' src={currentUser?.pic ?? toAbsoluteUrl('/media/avatars/300-3.jpg')} />
        </div>
        <HeaderUserMenu />
      </div>
    </div>
  )
}

export { Navbar }
