import { toast as TM } from 'react-toastify'
type TOAST_TYPE = 'SUCCESS' | 'ERROR'
interface Props {
  message: string
  toastType?: TOAST_TYPE
  duration?: number | false | undefined
}
const toast = ({ toastType, message, duration = 5000 }: Props) => {
  switch (toastType) {
    case 'SUCCESS':
      return TM.success(message, {
        position: 'top-right',
        autoClose: duration,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      })
    case 'ERROR':
      return TM.error(message, {
        position: 'top-right',
        autoClose: duration,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      })
    default:
      return TM.info(message, {
        position: 'top-right',
        autoClose: duration,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      })
  }
}
export { toast }
