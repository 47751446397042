import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import _ from 'lodash'
import { axiosInstance } from '../../api'
import { IProposal } from '../../models'
import { LIST } from '../../models/list.model'
import { CARD } from '../../models/card.model'

import { toast } from '../../utils/toast'
// import { showMessage } from 'app/store/fuse/messageSlice';


interface CREATEBOARD {
  type?: string
  name: string
  description?: string
  // writer: string
  // approver: string
  // reviewer: string
  dueDate: Date,
  reportingDate: Date
  proposalFiles?: string[],
  uploadGuidelines?: any[],
  sourceDocuments?: any[]

}
/**
 * Create Board
 */
export const createNewBoard = createAsyncThunk(
  '/board/create_board',
  async (boardInfo: CREATEBOARD, { dispatch, getState }: any) => {
    const { data } = await axiosInstance.post('/board/create_board', boardInfo)
    return data.data.board
  }
)
/**
 * Get Board List
 */

interface GetBoardsListArgs {
  sortType: string;
  search: string;
}

interface GetBoardsListResponse {
  boardList: [];
}


export const getBoardsList = createAsyncThunk<GetBoardsListResponse, GetBoardsListArgs>(
  'getBoardList',
  async ({ sortType, search }, { dispatch }) => {
    try {
      const queryParams = search ? { sortType, query: search } : { sortType };
      const { data } = await axiosInstance.get(`/board/get_board_list`, { params: queryParams });
      return data.data.boardList;
    } catch (error) {
      return [];
    }
  }
);

export const getBoardsListWithSorts = createAsyncThunk<GetBoardsListResponse, GetBoardsListArgs>(
  'getBoardList',
  async ({ sortType, search }, { dispatch }) => {
    try {
      const queryParams = search ? { sortType, query: search } : { sortType };
      const { data } = await axiosInstance.get(`/board/get_board_list_with_sorts`, { params: queryParams });
      return data.data.boardList;
    } catch (error) {
      return [];
    }
  }
);

export const getBoard = createAsyncThunk('getBoard', async (boardId: string, { dispatch }) => {
  try {
    const { data } = await axiosInstance.get(`/board/get_board`, { params: { boardId } })
    return data.data
  } catch (error) {
    return {}
  }
})


export const moveCard = createAsyncThunk(
  'board/moveCard',
  async (result: any, { dispatch, getState }: any) => {
    try {
      const { board } = getState().scrmapp

      const { data } = await axiosInstance.put(`/card/move_card`, { boardId: board.id, result })
      const response =
        await axiosInstance.post('/card/run_operations',
          { cardId: result.draggableId, sourceListId: result.source.droppableId, });

      return data?.data
    } catch (error: any) {
      toast({ toastType: 'ERROR', message: 'Error while processing document', duration: false })
      // if fail then move to source
      dispatch(moveCardToSource({
        cardId: result.draggableId,
        sourceId: result.source.droppableId,
        destinationId: result.destination.droppableId
      }))
      throw new Error('not implemented')
    }
  }
)

export const runOperations = createAsyncThunk(
  'board/runOperation',
  async ({ cardId, sourceListId, destinationId }: any, { dispatch, getState, rejectWithValue }: any) => {
    try {
      const { board } = getState().scrmapp;
      const response = await axiosInstance.post('/card/run_operations', { cardId, sourceListId });
      toast({ toastType: 'SUCCESS', message: 'Your documents processed successfully', duration: false })
      return response.data?.data;
    } catch (error: any) {
      console.log('error', error)
    }
  }
);

export const deleteBoard = createAsyncThunk(
  'board/delete',
  async ({ boardId }: any, { dispatch, getState }: any) => {
    try {
      const { data } = await axiosInstance.delete(`/board/delete_board/${boardId}`)
      toast({ toastType: 'SUCCESS', message: 'Proposal Deleted Successfully', duration: false })
      return true
    } catch (error: any) {
      toast({ toastType: 'ERROR', message: 'Error While Deleting Proposal', duration: false })
      return {}
    }
  }
)

export const uploadCardFile: any = createAsyncThunk(
  'cards/uploadFile',
  async ({ listId, file, boardId }: any, { dispatch, getState }: any) => {
    try {
      // const uploadedFileResponse: any = await uploadFileToS3(file, false)
      const { data } = await axiosInstance.post(`/card/create_card_upload_file`, {
        // file: { ...uploadedFileResponse },
        listId,
        boardId,
      })

      console.log("data", data)
      return data.data.cardInfo
    } catch (error) {
      console.log('error', error)
      return {}
    }
  }
)

export const moveBoard: any = createAsyncThunk(
  'board/move',
  async (moveInfo: any, { dispatch, getState }: any) => {
    try {

      const { data } = await axiosInstance.post(`/board/move_board`, { ...moveInfo })
      // return data.data.cardInfo
    } catch (error) {
      console.log('error', error)
      return {}
    }
  }
)

const boardsListSlice = createSlice({
  name: 'boardList',
  initialState: {
    id: '',
    title: '',
    description: '',
    boardList: [] as IProposal[],
    lists: [] as LIST[],
    uploadedGuideLines: []
  },
  reducers: {
    clearBoardList: (state: any) => {
      state.boardList = [];
    },
    addUploadingFile: (
      state: any,
      { payload }: { payload: { listId?: string; cardId: string; fileInfo?: any } }
    ) => {
      const { cardId, listId, fileInfo } = payload
      if (!cardId || !listId) return
      const listIndex = state.lists.findIndex((list) => list._id === listId)
      if (listIndex < 0) return
      const cardIndex = state.lists[listIndex].cards.findIndex((card) => card._id === cardId)
      state.lists[listIndex].cards[cardIndex].attachedFiles.push(fileInfo)
    },
    moveCardToSource: (
      state: any,
      { payload }: { payload: { sourceId: string; destinationId: string; cardId: string } }
    ) => {
      const { sourceId, destinationId, cardId } = payload;
      const sourceListIndex = state.lists.findIndex((list) => list._id === sourceId);
      const destinationListIndex = state.lists.findIndex((list) => list._id === destinationId);

      if (sourceListIndex < 0 || destinationListIndex < 0) return;

      const cardIndex = state.lists[destinationListIndex].cards.findIndex((card) => card._id === cardId);
      if (cardIndex < 0) return;

      const cardToMove = state.lists[destinationListIndex].cards.splice(cardIndex, 1)[0];
      if (!cardToMove) return;

      state.lists[sourceListIndex].cards.push(cardToMove);
    },
    toggleDeleteModal: (state: any, action) => {
      state.deleteModal = action.payload
    },
  },
  extraReducers: {
    [getBoard.fulfilled.toString()]: (state, action) => {
      state.id = action?.payload?.board?._id || ''
      state.title = action?.payload?.board?.name || ''
      state.description = action?.payload?.board?.description || ''
      state.lists = action?.payload?.board?.lists || []
      state.uploadedGuideLines = action?.payload?.board?.uploadGuidelines || []
    },

    [moveCard.fulfilled.toString()]: (state, action) => {
      // const { cardInfo, slistId, dlistId } = action?.payload
      // if (!slistId && !dlistId) return
      // const sourceIndex = state?.lists?.findIndex((el: any) => el._id === slistId)
      // const destinationIndex = state?.lists?.findIndex((el: any) => el._id === dlistId)
      // state.lists[sourceIndex].cards = state.lists[sourceIndex].cards.filter(
      //   (card: CARD) => card._id !== cardInfo._id
      // )
      // state.lists[destinationIndex].cards.push(cardInfo)
    },
    [uploadCardFile.fulfilled.toString()]: (state: any, action) => {
      const cardData = action.payload
      state.lists = state?.lists?.map((list) =>
        list?._id === cardData?.listId ? { ...list, cards: [cardData] } : list
      )
    },

    [createNewBoard.fulfilled.toString()]: (state, action) => {
      state.boardList.push(action.payload)
    },
    [getBoardsList.fulfilled.toString()]: (state, action) => {
      state.boardList = action.payload
    },
  },
})

export const { addUploadingFile, moveCardToSource, toggleDeleteModal, clearBoardList } = boardsListSlice.actions
export default boardsListSlice.reducer

