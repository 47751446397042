import {Outlet} from 'react-router-dom'

const ErrorsLayout = () => {

  return (
    <div className='d-flex flex-column flex-root'>
      <div className='d-flex flex-column flex-center flex-column-fluid'>
        <div className='d-flex flex-column flex-center text-center p-10'>
          <Outlet />
        </div>
      </div>
    </div>
  )
}

export {ErrorsLayout}
