import AWS from 'aws-sdk';
import { getFileType } from '../../_metronic/helpers';
const BUCKET_NAME = 'askelle-test'

const s3 = new AWS.S3({
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  region: process.env.REACT_APP_AWS_REGION
});


async function uploadFileToS3(file: File, updateFile: boolean, customExtension: boolean) {


  const sanitizedFileName = file.name.replace(/\s+/g, '_');
  const fileName = updateFile ? file.name : sanitizedFileName + Date.now()


  const params = {
    Bucket: BUCKET_NAME,
    Key: fileName,
    ContentType: file.type,
    Body: file,
    ACL: 'public-read',
  };

  const result = await s3.putObject(params).promise();

  const { region } = s3.config;
  const s3ObjectLink = `https://${BUCKET_NAME}.s3.${region}.amazonaws.com/${encodeURIComponent(
    fileName
  )}`;


  const mimetype = file.type
  const fileSize = file.size;
  const url = s3ObjectLink;

  return { fileSize, mimetype, fileName, url, result, originalName: customExtension ? file.name + getFileType(file.type)?.extension : file.name };
}

export default uploadFileToS3;
