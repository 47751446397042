import {useEffect} from 'react'
import {Outlet, useLocation} from 'react-router-dom'

import {reInitMenu} from '../helpers'
import {PageDataProvider} from './core'
import {Content} from './components/content'
// import {FooterWrapper} from './components/footer'
import {HeaderWrapper} from './components/header'
import {ThemeModeComponent} from '../assets/ts/layout'

const PublicLayout = () => {
  const location = useLocation()
  useEffect(() => {
    reInitMenu()
  }, [location.key])
  const bgcolor = ThemeModeComponent.getMode() === 'dark' ? '#1b1b1d' : ''
  return (
    <PageDataProvider>
      <div className='d-flex flex-column flex-root app-root' id='kt_app_root'>
        <div className='app-page flex-column flex-column-fluid' id='kt_app_page'>
          <HeaderWrapper />
          <div
            className={`app-wrapper flex-column flex-row-fluid ${bgcolor}`}
            id='kt_app_wrapper'
            style={{backgroundColor: bgcolor}}
          >
            {' '}
            <div className='app-main flex-column flex-row-fluid' id='kt_app_main'>
              <div className='d-flex flex-column flex-column-fluid'>
                <Content>
                  <Outlet />
                </Content>
              </div>
              {/* <FooterWrapper /> */}
            </div>
          </div>
        </div>
      </div>
    </PageDataProvider>
  )
}

export {PublicLayout}
