/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import { Link, useLocation, useParams } from 'react-router-dom'
import { useFormik } from 'formik'
import { getUserByToken, login, resetPassword } from '../core/_requests'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'
import { useAuth } from '../core/Auth'
import { AxiosError, AxiosResponse } from 'axios'

const resetPassSchema = Yup.object().shape({
  newPassword: Yup.string()
    .min(5, 'Minimum 5 length password')
    .max(50, 'Maximum 50 length password')
    .required('Password is required'),
  confirmPassword: Yup.string()
    .min(5, 'Minimum 5 length password')
    .max(50, 'Maximum 50 length password')
    .required('Confirm password is required')
    .oneOf([Yup.ref('newPassword')], "Password and Confirm Password didn't match"),
})

const initialValues = {
  newPassword: '',
  confirmPassword: '',
}


export function ResetPassword() {

  const [loading, setLoading] = useState(false)
  const { saveAuth, setCurrentUser } = useAuth()
  const [success, setSuccess] = useState('')

  const { token } = useParams()

  const afterReset = () => {

  }

  const formik = useFormik({
    initialValues,
    validationSchema: resetPassSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true)
      try {
        if (token) {
          const res: AxiosResponse = await resetPassword(token, values.confirmPassword)
          setSuccess(res?.data?.message)
          setStatus(undefined)
          setSubmitting(false)
          setLoading(false)
          setTimeout(() => {
            window.location.href = '/auth'
          }, 3000)

        }
      } catch (error) {
        if (error instanceof AxiosError) {
          saveAuth(undefined)
          setStatus(error?.response?.data?.message)
          setSubmitting(false)
          setLoading(false)
        }
      }
    },
  })

  return (
    <form
      className='form w-100'
      onSubmit={formik.handleSubmit}
      noValidate
      id='kt_login_signin_form'
    >
      {/* begin::Heading */}
      <div className='text-center mb-11'>
        <h1 className='text-heading fw-bolder' style={{ marginBottom: '16px' }}>
          Reset Password
        </h1>
        <div className='text-heading fw-semibold fs-6'>Enter new password</div>
      </div>

      {success && (
        <div className='mb-10 bg-light-info p-8 rounded'>
          <div className='text-info'>{success}</div>
        </div>
      )}

      {formik.status && (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      )}

      {/* begin::Form group */}
      <div className='fv-row mb-3'>
        <input
          placeholder='New password'
          type='password'
          {...formik.getFieldProps('newPassword')}
          className={clsx(
            'form-control bg-light border-stroke',
            { 'is-invalid': formik.touched.newPassword && formik.errors.newPassword },
            {
              'is-valid': formik.touched.newPassword && !formik.errors.newPassword,
            }
          )}
          name='newPassword'
          autoComplete='off'
        />
        {formik.touched.newPassword && formik.errors.newPassword && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.newPassword}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='fv-row mb-8'>
        <input
          type='password'
          autoComplete='off'
          placeholder='Confirm password'
          {...formik.getFieldProps('confirmPassword')}
          className={clsx(
            'form-control bg-light border-stroke',
            {
              'is-invalid': formik.touched.confirmPassword && formik.errors.confirmPassword,
            },
            {
              'is-valid': formik.touched.confirmPassword && !formik.errors.confirmPassword,
            }
          )}
        />
        {formik.touched.confirmPassword && formik.errors.confirmPassword && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.confirmPassword}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Action */}
      <div className='d-grid mb-10'>
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-primary'
          disabled={formik.isSubmitting || !formik.isValid || formik?.values?.confirmPassword !== formik?.values?.newPassword}
        >
          {!loading && <span className='indicator-label'>Update Password</span>}
          {loading && (
            <span className='indicator-progress' style={{ display: 'block' }}>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>
      {/* end::Action */}

    </form>
  )
}
