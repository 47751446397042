import axios from 'axios';
import { AuthModel } from '../modules/auth';
import { API_URL } from '../modules/auth/core/_requests';

export const axiosInstance = axios.create({
  baseURL: API_URL
});

const ResponseInterceptor = (response) => {
  return response;
};

const RequestInterceptor = (config) => {
  const userInfoString = localStorage.getItem('kt-auth-react-v');

  if (userInfoString) {
    const userInfo: AuthModel = JSON.parse(userInfoString);
    if (userInfo.api_token) {
      config.headers = {
        ...config.headers,
        Authorization: 'Bearer ' + userInfo.api_token,
        Accept: 'application/json',
      };
    }
  }

  return config; // Return the modified config object
};

axiosInstance.interceptors.response.use(ResponseInterceptor, async (error) => {
  if (error.response.data) {
    return Promise.reject(error.response.data);
  }
  return Promise.reject(error.response);
});

axiosInstance.interceptors.request.use(RequestInterceptor, (error) => {
  return Promise.reject(error);
});

export default axiosInstance;
