import axios from 'axios'
import { AuthModel, ResetPasswordModel, UserModel } from './_models'

export const API_URL = process.env.REACT_APP_API_URL
export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/verify_token`
export const LOGIN_URL = `${API_URL}/auth/login`
export const REGISTER_URL = `${API_URL}/auth/signup`
export const REQUEST_PASSWORD_URL = `${API_URL}/forgot_password`
export const RESET_PASSWORD_URL = `${API_URL}/auth/verify_reset_token`

// Server should return AuthModel
export function login(email: string, password: string) {
  return axios.post<AuthModel>(LOGIN_URL, {
    email,
    password,
  })
}


export function resetPassword(token: string, password: string) {
  return axios.patch<ResetPasswordModel>(RESET_PASSWORD_URL, {
    token,
    password,
  })
}

// Server should return AuthModel
export function register(email: string, password: string, confirmPassword: string, fName: string, lName: string) {
  return axios.post(REGISTER_URL, {
    email,
    password,
    confirmPassword,
    fName,
    lName
  })
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.post<{ result: boolean }>(REQUEST_PASSWORD_URL, {
    email,
  })
}

export function getUserByToken(token: string) {
  return axios.post<UserModel>(GET_USER_BY_ACCESSTOKEN_URL, {
    api_token: token,
  })
}


