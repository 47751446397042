/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState, useEffect } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
import { getUserByToken, register } from '../core/_requests'
import { PasswordMeterComponent } from '../../../../_metronic/assets/ts/components'
import { useAuth } from '../core/Auth'
import { Link } from 'react-router-dom'
import { AxiosError } from 'axios'
import { updatePasswordMeter } from '../../../utils/checkPasswordStrength'

const initialValues = {
  email: '',
  password: '',
  confirmPassword: '',
  acceptTerms: false,
  fName: '',
  lName: ''
}

const registrationSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  fName: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .required('First Name is required'),
  lName: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .required('Last Name is required'),
  password: Yup.string()
    .min(8, 'Minimum 8 character password')
    .max(50, 'Maximum 8 character password')
    .required('Password is required'),
  confirmPassword: Yup.string()
    .min(8, 'Minimum 8 character password')
    .max(50, 'Maximum 8 character password')
    .required('Password confirmation is required')
    .oneOf([Yup.ref('password')], "Password and Confirm Password didn't match"),
  acceptTerms: Yup.bool().required('You must accept the terms and conditions'),
})

export function Registration() {
  const [loading, setLoading] = useState(false)
  const { saveAuth, setCurrentUser } = useAuth()
  const [success, setSuccess] = useState('')
  const formik = useFormik({
    initialValues,
    validationSchema: registrationSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true)
      try {
        const { data: auth } = await register(values.email, values.password, values.confirmPassword, values.fName, values.lName)
        if (auth?.user) {
          setTimeout(() => {
            setSuccess('Successfully registered!')
          }, 2000)
        }
      } catch (error) {
        if (error instanceof AxiosError) {
          saveAuth(undefined)
          setStatus(error?.response?.data?.message)
          setSubmitting(false)
          setLoading(false)
        }

      }
    },
  })

  useEffect(() => {
    updatePasswordMeter(formik.values.password)
  }, [formik.values.password])

  return (
    <form
      className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
      noValidate
      id='kt_login_signup_form'
      onSubmit={formik.handleSubmit}
    >
      {/* begin::Heading */}
      <div className='text-center mb-11'>
        {/* begin::Title */}
        <h1 className='text-dark fw-bolder mb-3'>Sign Up</h1>
        {/* end::Title */}

        <div className='text-gray-500 fw-semibold fs-6'>Double your bandwidth</div>
      </div>
      {/* end::Heading */}

      {/* <div className='row g-3 mb-9'>
        <div className='col-md-6'>
          <a
            href='#'
            className='btn btn-flex btn-outline bg-light border-stroke btn-text-gray-700 btn-active-color-primary bg-state-light flex-center text-nowrap w-100'
          >
            <img
              alt='Logo'
              src={toAbsoluteUrl('/media/svg/brand-logos/google-icon.svg')}
              className='h-15px me-3'
            />
            Sign in with Google
          </a>
        </div>

        <div className='col-md-6'>
          <a
            href='#'
            className='btn btn-flex btn-outline bg-light border-stroke btn-text-gray-700 btn-active-color-primary bg-state-light flex-center text-nowrap w-100'
          >
            <img
              alt='Logo'
              src={toAbsoluteUrl('/media/svg/brand-logos/outlook.svg')}
              className='h-15px me-3'
            />
            Microsoft Outlook
          </a>
        </div>
      </div>

      <div className='separator separator-content my-14'>
        <span className='w-125px text-paragraph fw-semibold fs-7'>Or with email</span>
      </div> */}
      {success && (
        <div className='mb-10 bg-light-info p-8 rounded'>
          <div className='text-info'>{success}</div>
        </div>
      )}

      {formik.status && (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      )}

      {/* begin::Form group Email */}

      <div className='d-flex gap-3 w-100 mb-8'>
        <div className='d-flex w-100 flex-column'>
          <input
            placeholder='First Name'
            type='text'
            autoComplete='off'
            {...formik.getFieldProps('fName')}
            className={clsx(
              'form-control bg-transparent',
              { 'is-invalid': formik.touched.fName && formik.errors.fName },
              {
                'is-valid': formik.touched.fName && !formik.errors.fName,
              }
            )}
          />
          {formik.touched.fName && formik.errors.fName && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.fName}</span>
              </div>
            </div>
          )}
        </div>
        <div className='d-flex w-100 flex-column'>
          <input
            placeholder='Last Name'
            type='text'
            autoComplete='off'
            {...formik.getFieldProps('lName')}
            className={clsx(
              'form-control bg-transparent',
              { 'is-invalid': formik.touched.lName && formik.errors.lName },
              {
                'is-valid': formik.touched.lName && !formik.errors.lName,
              }
            )}
          />
          {formik.touched.lName && formik.errors.lName && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.lName}</span>
              </div>
            </div>
          )}
        </div>
      </div>

      <div className='fv-row mb-8'>
        <input
          placeholder='Email'
          type='email'
          autoComplete='off'
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control bg-transparent',
            { 'is-invalid': formik.touched.email && formik.errors.email },
            {
              'is-valid': formik.touched.email && !formik.errors.email,
            }
          )}
        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.email}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group Password */}
      <div className='fv-row mb-8' data-kt-password-meter='true'>
        <div className='mb-1'>
          <div className='position-relative mb-3'>
            <input
              type='password'
              placeholder='Password'
              autoComplete='off'
              {...formik.getFieldProps('password')}
              className={clsx(
                'form-control bg-transparent',
                {
                  'is-invalid': formik.touched.password && formik.errors.password,
                },
                {
                  'is-valid': formik.touched.password && !formik.errors.password,
                }
              )}
            />
            {formik.touched.password && formik.errors.password && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.password}</span>
                </div>
              </div>
            )}
          </div>
          {/* begin::Meter */}
          <div
            className='d-flex align-items-center mb-3'
            id='passwordMeter'
            data-kt-password-meter-control='highlight'
          >
            <div className='flex-grow-1  rounded h-5px me-2'></div>

          </div>
          {/* end::Meter */}
        </div>
        <div className='text-muted'>
          Use 8 or more characters with a mix of letters, numbers & symbols.
        </div>
      </div>
      {/* end::Form group */}

      {/* begin::Form group Confirm password */}
      <div className='fv-row mb-5'>
        <input
          type='password'
          placeholder='Repeat Password'
          autoComplete='off'
          {...formik.getFieldProps('confirmPassword')}
          className={clsx(
            'form-control bg-transparent',
            {
              'is-invalid': formik.touched.confirmPassword && formik.errors.confirmPassword,
            },
            {
              'is-valid': formik.touched.confirmPassword && !formik.errors.confirmPassword,
            }
          )}
        />
        {formik.touched.confirmPassword && formik.errors.confirmPassword && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.confirmPassword}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='fv-row mb-8'>
        <label className='form-check form-check-inline' htmlFor='kt_login_toc_agree'>
          <input
            className='form-check-input'
            type='checkbox'
            id='kt_login_toc_agree'
            {...formik.getFieldProps('acceptTerms')}
          />
          <span>
            I Accept the{' '}
            <a
              href='https://keenthemes.com/metronic/?page=faq'
              target='_blank'
              className='ms-1 link-primary'
            >
              Terms and Conditions
            </a>
            .
          </span>
        </label>
        {formik.touched.acceptTerms && formik.errors.acceptTerms && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.acceptTerms}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='text-center'>
        <button
          type='submit'
          id='kt_sign_up_submit'
          className='btn btn-lg btn-primary w-100 mb-5'
          disabled={formik.isSubmitting || !formik.isValid || !formik.values.acceptTerms}
        >
          {!loading && <span className='indicator-label'>Submit</span>}
          {loading && (
            <span className='indicator-progress' style={{ display: 'block' }}>
              Please wait...{' '}
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>
      {/* end::Form group */}

      <div className='text-gray-500 text-center fw-semibold fs-6'>
        Already a member?{' '}
        <Link to='/auth' className='link-primary'>
          Sign In
        </Link>
      </div>
    </form>
  )
}
