/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useRef } from 'react'
import { Outlet, Link } from 'react-router-dom'
import { toAbsoluteUrl } from '../../../_metronic/helpers'

const AuthLayout = () => {
  const vidRef = useRef();
  useEffect(() => {
    const root = document.getElementById('root')
    if (root) {
      root.style.height = '100%'
    }
    return () => {
      if (root) {
        root.style.height = 'auto'
      }
    }
  }, [])

  useEffect(() => {
    const video = document.getElementById("loginVideo");
    if (video instanceof HTMLMediaElement) {
      video.volume = 0.3;
    }
  }, [])

  return (
    <div className='d-flex flex-column flex-lg-row flex-column-fluid h-100'>
      {/* begin::Body */}
      <div className='d-flex flex-column flex-lg-row-fluid w-lg-50 p-10 order-2 order-lg-1'>
        {/* begin::Form */}
        <div className='d-flex flex-center flex-column flex-lg-row-fluid'>
          {/* begin::Wrapper */}
          <div className='w-lg-500px p-10'>
            <Outlet />
          </div>
          {/* end::Wrapper */}
        </div>
        {/* end::Form */}

        {/* begin::Footer */}
        <div className='d-flex flex-center flex-wrap px-5 w-100 justify-content-evenly mw-700px align-self-center '>
          <a href='#' className='px-5 text-heading' target='_blank'>
            English
          </a>

          {/* begin::Links */}
          <div className='d-flex fw-semibold text-primary fs-base'>
            <div className='d-flex fw-semibold text-primary fs-base'>
              <a href='#' className='px-5 text-primary' target='_blank'>
                Terms
              </a>

              <a href='#' className='px-5 text-primary' target='_blank'>
                Tiers
              </a>
            </div>
          </div>
          {/* end::Links */}
        </div>
        {/* end::Footer */}
      </div>
      {/* end::Body */}

      {/* begin::Aside */}
      <div
        className='d-flex position-relative flex-lg-row-fluid w-lg-50 bgi-size-cover bgi-position-center order-1 order-lg-2'
        style={{ backgroundImage: `url(${toAbsoluteUrl('/media/misc/auth-bg.png')})` }}
      >
        {/* begin::Content */}
        <div
          className='d-flex flex-column flex-center py-15 px-5 px-md-15 w-100'
          style={{ zIndex: 1 }}
        >
          {/* begin::Logo */}
          <Link to='/' className='mb-12'>
            <img
              alt='Logo'
              src={toAbsoluteUrl('/media/logos/logo.svg')}
              style={{ height: 165, width: 132 }}
            />
          </Link>
          {/* end::Logo */}

          {/* TODO: Replace video placeholder with the video component */}
          {/* begin::Video */}
          <div className='mx-auto w-275px w-md-50 w-xl-500px mb-10 mb-lg-20 h-378px rounded-4  d-flex justify-content-center align-items-center fs-4 fw-bold '>
            <video

              style={{
                width: '500px',
                borderRadius: '18px'
              }}
              id="loginVideo"
              src={toAbsoluteUrl('/media/videos/login.mp4')}
              controls={false}
              autoPlay={true}
              muted



            />
          </div>
          {/* end::Video */}

          {/* begin::Title */}
          <h1 className='text-white fs-2qx fw-bolder text-center mb-7'>Welcome to askelle</h1>
          {/* end::Title */}

          {/* begin::Text */}
          <div className='text-white fs-base text-center'>
            <p className=''>
              where you can safely use your own data in the capabilities of <br /> a large language
              model!
            </p>

            <p>
              Powered by <span className='text-primary'>NVidia</span>,{' '}
              <span className='text-primary'>askelle</span> provides a{' '}
              <span className='text-primary'>private</span> and{' '}
              <span className='text-primary'>secure</span> space <br />
              to learn and store your documents, and{' '}
              <span className='text-primary'>integrates</span> several <br />
              technology tools to support your <span className='text-primary'>proposal</span> needs.
            </p>
          </div>
          {/* end::Text */}
        </div>
        {/* end::Content */}

        {/* begin::Pattern */}
        <div
          className='position-absolute top-0 bottom-0 start-0 end-0'
          style={{
            backgroundImage: `url(${toAbsoluteUrl('/media/misc/auth-pattern.png')})`,
            zIndex: 0,
          }}
        />
        {/* end::Pattern */}
      </div>
      {/* end::Aside */}
    </div>
  )
}

export { AuthLayout }
