import {FC} from 'react'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useLocation} from 'react-router'
import {checkIsActive, toAbsoluteUrl, WithChildren} from '../../../../helpers'

type Props = {
  to: string
  title: string
  icon: string
  hasBullet?: boolean
  withActiveState?: boolean
}

const SidebarMenuItem: FC<Props & WithChildren> = ({
  children,
  to,
  title,
  icon,
  withActiveState = true,
  hasBullet = false,
}) => {
  const {pathname} = useLocation()
  const isActive = checkIsActive(pathname, to)

  return (
    <div className='menu-item position-relative'>
      {isActive && withActiveState && (
        <div className='position-absolute left-0 top-0 bottom-0 mt-xs mb-xs bg-primary w-4px rounded' />
      )}
      <Link
        className={clsx('menu-link without-sub', {active: withActiveState && isActive})}
        to={to}
      >
        {hasBullet && (
          <span className='menu-bullet'>
            <span className='bullet bullet-dot'></span>
          </span>
        )}
        {icon && (
          <img
            src={toAbsoluteUrl(`/media/svg/${icon}.svg`)}
            className='tw-mr-3 tw-w-6 tw-h-6'
            alt='Icon'
          />
        )}
        <span className='menu-title text-truncate '>{title}</span>
      </Link>
      {children}
    </div>
  )
}

export {SidebarMenuItem}
