import { FC } from 'react'
import { Link } from 'react-router-dom'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'

const Error404: FC = () => {
  return (
    <>
      {/* begin::Illustration */}
      <div className='mb-3'>
        <img src={toAbsoluteUrl('/media/auth/404-error.svg')} className='mw-100 mh-300px' alt='' />
      </div>
      {/* end::Illustration */}

      {/* begin::Text */}
      <div className='fw-semibold fs-6 text-gray-500 mb-7'>Seems there is nothing here</div>
      {/* end::Text */}

      {/* begin::Link */}
      <div className='mb-0'>
        <Link to='/proposals' className='btn btn-sm btn-primary'>
          Back to Home
        </Link>
      </div>
      {/* end::Link */}
    </>
  )
}

export { Error404 }
