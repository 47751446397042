import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import _ from 'lodash'
import axiosInstance from '../../api'
import { toast } from '../../utils/toast'
import uploadFileToS3 from '../../utils/s3FileUpload'

export const getCards = createAsyncThunk('scrumboardApp/cards/getCards', async (boardId) => {
  const response = await axios.get(`/api/scrumboard/boards/${boardId}/cards`)
  const data = await response.data

  return data
})

export const uploadFile: any = createAsyncThunk(
  'scrumboardApp/cards/uploadFile',
  async ({ listId, file }: any, { dispatch, getState }: any) => {
    try {
      const { board } = getState().scrmapp
      const formData = new FormData()
      formData.append('file', file)
      formData.append('listId', listId)
      formData.append('boardId', board._id)
      const { data } = await axiosInstance.post(`/card/create_card_upload_file`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
      return data.data.cardInfo
    } catch (error) {
      console.log('error', error)
      return {}
    }
  }
)
export const deleteCardFile: any = createAsyncThunk(
  'scrumboardApp/cards/deleteFile',
  async ({ cardId, file }: any, { dispatch, getState }: any) => {
    try {
      const { data } = await axiosInstance.delete(`/card/delete_card_file`, {
        params: { cardId, file },
      })
      return data.data.cardInfo
    } catch (error) {
      console.log('error', error)
      return {}
    }
  }
)
export const updateQueriedFile: any = createAsyncThunk(
  'scrumboardApp/cards/updateQueriedFile',
  async (
    { cardId, fileContent, fileName, id }: { id: string, cardId: string; fileContent: string, fileName: string },
    { dispatch, getState }: any
  ) => {
    try {

      const file = new Blob([fileContent], { type: 'text/plain;charset=utf-8' });
      const convertedFile = new File([file], fileName, { type: 'text/plain;charset=utf-8' });
      const updatedFileResponse = await uploadFileToS3(convertedFile, true, true)
      const { data } = await axiosInstance.put(`/card/update_queried_file`, {
        cardId,
        id,
        fileSize: updatedFileResponse.fileSize
      })
      toast({ toastType: 'SUCCESS', message: 'Your Queried document updated successfully!', duration: false })
      return { cardInfo: data.data.cardInfo }
    } catch (error) {
      toast({ toastType: 'ERROR', message: 'Error while updating file!', duration: false })
      return {}
    }
  }
)
const cardsAdapter = createEntityAdapter({})

export const { selectAll: selectCards, selectById: selectCardById } = cardsAdapter.getSelectors(
  (state: any) => state.scrumboardApp.cards
)

const cardsSlice = createSlice({
  name: 'scrumboardApp/cards',
  initialState: {
    summarizedDocModal: false,
    editorModal: false,
    fileInfo: {},
  },
  reducers: {
    handleSummarizedDocModal: (
      state: any,
      { payload }: { payload: { show?: boolean; fileInfo?: { cardId: string; fileName: string } } }
    ) => {
      const { show, fileInfo } = payload
      if (show) {
        state.fileInfo = fileInfo
      } else {
        state.fileInfo = {}
      }
      state.summarizedDocModal = show
    },

  },
  extraReducers: {},
})

export const { handleSummarizedDocModal } = cardsSlice.actions

export default cardsSlice.reducer
