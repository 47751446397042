import { lazy, FC, Suspense } from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'
import TopBarProgress from 'react-topbar-progress-indicator'

import { WithChildren } from '../../_metronic/helpers'
import { PublicLayout } from '../../_metronic/layout/PublicLayout'
import { getCSSVariableValue } from '../../_metronic/assets/ts/_utils'

const PublicRoutes = () => {
  const PlansPage = lazy(() => import('../pages/plans'))

  return (
    <Routes>

      <Route element={<PublicLayout />}>
        {/* Pages */}
        <Route
          path='plans'
          element={
            <SuspensedView>
              <PlansPage />
            </SuspensedView>
          }
        />
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>

    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export { PublicRoutes }
