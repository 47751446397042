function calculatePasswordStrength(password: string) {
  // Check for a mix of letters, numbers, and symbols
  const hasLetters = /[a-zA-Z]/.test(password);
  const hasNumbers = /\d/.test(password);
  const hasSymbols = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]/.test(password);

  // Calculate strength based on the criteria
  const lengthFactor = Math.min(password.length / 8, 1); // Assuming a minimum length of 8 characters
  const letterFactor = hasLetters ? 1 : 0;
  const numberFactor = hasNumbers ? 1 : 0;
  const symbolFactor = hasSymbols ? 1 : 0;

  const strength = (lengthFactor + letterFactor + numberFactor + symbolFactor) / 4 * 100;

  return strength;
}

export function updatePasswordMeter(password: string) {
  const strength = calculatePasswordStrength(password);
  const meter = document.getElementById('passwordMeter');

  // Update the classes based on the password strength
  if (meter)
    if (strength < 25) {
      meter.className = 'd-flex align-items-center mb-3';
    } else if (strength < 50) {
      meter.className = 'd-flex align-items-center mb-3 bg-warning';
    } else if (strength < 75) {
      meter.className = 'd-flex align-items-center mb-3 bg-info';
    } else {
      meter.className = 'd-flex align-items-center mb-3 bg-success';
    }
}
