import React from 'react';
import { toAbsoluteUrl } from '../../../_metronic/helpers';


const TermsAndConditions = () => {
  return (
    <div className='m-auto d-flex py-20 flex-column w-50 items-center'>
      <img
        alt='Logo'
        src={toAbsoluteUrl('/media/logos/logo.svg')}
        className='h-100px'
      />
      <h1>Terms and Conditions</h1>
      <h2>1. Acceptance of Terms</h2>
      <p>
        By using this website, you agree to comply with and be bound by the following terms and conditions of use.
        If you do not agree to these terms, please do not use this website.
      </p>

      <h2>2. Use of the Website</h2>
      <p>
        You agree to use this website for lawful purposes only and in a manner consistent with all applicable laws
        and regulations. You also agree not to interfere with the proper operation of the website.
      </p>

      <h2>3. Intellectual Property</h2>
      <p>
        All content on this website is the property of [Your Company] and is protected by intellectual property laws.
        You may not reproduce, distribute, or transmit any content without the prior written consent of [Your Company].
      </p>

      <h2>4. Disclaimer of Warranties</h2>
      <p>
        In publishing and graphic design, Lorem ipsum (/ˌlɔː.rəm ˈɪp.səm/) is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. Lorem ipsum may be used as a placeholder before final copy is available. It is also used to temporarily replace text in a process called greeking, which allows designers to consider the form of a webpage or publication, without the meaning of the text influencing the design.

        Lorem ipsum is typically a corrupted version of De finibus bonorum et malorum, a 1st-century BC text by the Roman statesman and philosopher Cicero, with words altered, added, and removed to make it nonsensical and improper Latin. The first two words themselves are a truncation of dolorem ipsum ("pain itself").

        Versions of the Lorem ipsum text have been used in typesetting at least since the 1960s, when it was popularized by advertisements for Letraset transfer sheets.[1] Lorem ipsum was introduced to the digital world in the mid-1980s, when Aldus employed it in graphic and word-processing templates for its desktop publishing program PageMaker. Other popular word processors, including Pages and Microsoft Word, have since adopted Lorem ipsum,[2] as have many LaTeX packages,[3][4][5] web content managers such as Joomla! and WordPress, and CSS libraries such as Semantic UI.[6]

      </p>

      <h2>5. Limitation of Liability</h2>
      <p>
        [Your Company] will not be liable to you for any direct or indirect loss or damage arising out of
        or in connection with your use of this website.
      </p>

      <h2>6. Governing Law</h2>
      <p>
        These terms and conditions are governed by and construed in accordance with the laws of [Your Country/State].
      </p>

      <h2>7. Changes to Terms and Conditions</h2>
      <p>
        In publishing and graphic design, Lorem ipsum (/ˌlɔː.rəm ˈɪp.səm/) is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. Lorem ipsum may be used as a placeholder before final copy is available. It is also used to temporarily replace text in a process called greeking, which allows designers to consider the form of a webpage or publication, without the meaning of the text influencing the design.
        Lorem ipsum is typically a corrupted version of De finibus bonorum et malorum, a 1st-century BC text by the Roman statesman and philosopher Cicero, with words altered, added, and removed to make it nonsensical and improper Latin. The first two words themselves are a truncation of dolorem ipsum ("pain itself").
        Versions of the Lorem ipsum text have been used in typesetting at least since the 1960s, when it was popularized by advertisements for Letraset transfer sheets.[1] Lorem ipsum was introduced to the digital world in the mid-1980s, when Aldus employed it in graphic and word-processing templates for its desktop publishing program PageMaker. Other popular word processors, including Pages and Microsoft Word, have since adopted Lorem ipsum,[2] as have many LaTeX packages,[3][4][5] web content managers such as Joomla! and WordPress, and CSS libraries such as Semantic UI.[6]

      </p>

      <h2>8. Contact Information</h2>
      <p>
        If you have any questions about these terms and conditions, please contact us at [Your Contact Email].
      </p>
    </div>
  );
};

export default TermsAndConditions;
